import React from 'react';

const Review = ({ name, date, image, review }) => (
  <div className="4u 12u$(small) card">
    <span className="image left">
      <img src={image} alt={name} />
    </span>
    <h3>{name}</h3>
    <p>
      <span className="icon fa-quote-left" />
      {review} <span className="icon fa-quote-right" />
    </p>
  </div>
);

export default Review;
