import React from 'react';
import { Helmet } from 'react-helmet';
import DJService from '../components/DJService';
import Layout from '../components/Layout';
import Review from '../components/Review';
import ClientsBanner from '../components/ClientsBanner';

import lisaAiden from '../assets/images/reviews/lisa-aiden.jpg';
import theresao from '../assets/images/reviews/theresa-o.jpg';
import hannahMouland from '../assets/images/reviews/hannah-mouland.jpg';
import jamieOusby from '../assets/images/reviews/jamie-ousby.jpg';
import danielleHall from '../assets/images/reviews/danielle-hall.jpg';
import shellyDoyle from '../assets/images/reviews/shelly-doyle.jpg';
import jadeVincent from '../assets/images/reviews/jade-vincent.jpg';
import juliaWilkin from '../assets/images/reviews/julia-wilkin.jpg';
import daveConnell from '../assets/images/reviews/dave-connell.jpg';

const ReviewsTemplate = () => (
  <Layout>
    <Helmet>
      <title>Function Band Bristol, Tribute Band Bath - Audio Vultures Reviews</title>
      <meta
        name="description"
        content="Reviews of Bristol based function band Audio Vultures. Why we are the number one function and tribute band in Bath, Bristol and Somerset"
      />
    </Helmet>

    <div id="main" className="alt reviews">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Reviews, Function Band Bristol</h1>
          </header>

          <div className="row">
            <div className="12u">
              <p>
                If you are looking for a premier function band in the Bristol and Bath areas or
                beyond then look no further! We have played all venues ranging from bars to the band
                area at Ashton Gate Stadium, and everything in-between!
              </p>

              <p>
                You can find our great reviews below, or you can see them direct on our{' '}
                <a
                  href="https://en-gb.facebook.com/audiovultures/"
                  target="_blank"
                  rel="noopener noreferrer">
                  Facebook
                </a>{' '}
                and{' '}
                <a
                  href="https://www.google.com/search?q=audio+vultures+google+reviews&oq=audio+vultures+google+reviews&aqs=chrome..69i57.4940j0j7&sourceid=chrome&ie=UTF-8#lrd=0x48718fd32d37c259:0x7f045ccf5b8d4e17,1,,,"
                  target="_blank"
                  rel="noopener noreferrer">
                  Google reviews
                </a>{' '}
                pages! Whatever the venue or occasion, we are guaranteed to ensure great energy and
                atmosphere with our party band hits that range from classics right up to today!
              </p>

              <p>
                If you’ve been looking for a premier function or tribute band in Bristol, Bath,
                Somerset or beyond then you’d need look no further! Simply{' '}
                <a href="#contact">contact us</a> today to discuss more.
              </p>
            </div>
          </div>
          <hr className="major" />
          <div className="row">
            <Review
              name="Lisa and Aden"
              image={lisaAiden}
              date="August 2019"
              review="Audio Vultures played at our Wedding on 10th August and were fab! Very professional
            and friendly. They played a great selections of songs and got lots of people on the
            dancefloor. Thanks for helping make our day so great!"
            />
            <Review
              name="Teresa Osgood"
              image={theresao}
              date="July 2019"
              review="Awesome band and awesome performance at a work charity event....amazing vocals,
              brilliant play list and genuinely lovely people!! Totally made our event a huge
              success!!"
            />
            <Review
              name="Hannah Mouland"
              image={hannahMouland}
              date="October 2019"
              review="Hi guys, a massive thank you from James and I for playing at our wedding
            last week. You were fantastic and so many guests have commented on how much they
            enjoyed you. You really did play a massive part in making our day truly perfect and
            we cannot thank you enough x"
            />
          </div>
          <hr className="major" />
          <div className="row">
            <Review
              name="Jamie Ousby"
              image={jamieOusby}
              date="September 2019"
              review="I’m a wedding photographer and I’ve shot over 1000 weddings, but Audio Vultures... Wow! ...they are non stop with the absolute best tunes, one of the top wedding bands in the country!
Would recommend anyone in the South West or beyond to check these guys out.
              Thanks for a great night and some awesome shots lads!"
            />
            <Review
              name="Danielle Hall"
              image={danielleHall}
              date="December 2018"
              review="Absolutely fantastic band doing a really wide range of covers. The guys themselves were really helpful and friendly and adapted to suit our venue. We hired them for a work Christmas party and they had no problem getting everyone up and dancing from the first song. Would definitely recommend them for any event!"
            />
            <Review
              name="Shelley Carr Doyle"
              image={shellyDoyle}
              date="August 2019"
              review="When you organise a party it's always a worry that you get the band right and people genuinely enjoy themselves. Thank you so much for all the compliments that I received about how great YOU are! We had a fantastic evening and even the non-dancers were very enthused about your set. Your lead singer has an amazing ability to cross genres and ranges and did so incredibly well. If I have anything else that needs a band, I'm coming to you!"
            />
          </div>
          <hr className="major" />
          <div className="row">
            <Review
              name="Jade Vincent"
              image={jadeVincent}
              date="January 2019"
              review="Great band, highly recommend, made my wedding reception keeping everybody on the dancefloor with their positive energy and up beat music. I honestly feel the band played a big part in keeping my guests happy and wanted more.
              Weddings are long busy days and if your guests aren’t entertained they will get bored and leave... Which is why I feel the Audio Vultures are a must have. Thank all so much."
            />
            <Review
              name="Julia Gurner-Wilkin"
              image={juliaWilkin}
              date="May 2019"
              review="Absolutely rocked my li’l Sisters wedding, Flolliefest 2019 ; we had an awesome evening with much boogieing an a little moshing. Thank you so much guys."
            />
            <Review
              name="Dave Connell "
              image={daveConnell}
              date="February 2019"
              review="Just wanted to say thank you for performing with us this New Year's Eve at The Windmill. You were great and I have heard nothing but great comments regarding both your live and your DJ sets. It was a fab night and I hope that you had as much fun as everyone else. Thank you for entertaining all of us."
            />
          </div>
          <hr className="major" />

          <div className="row">
            <div className="6u 12u$(small)">
              <h2>Read More Reviews</h2>
              <p>
                {' '}
                <span className="icon alt fa-facebook" /> Read More of our reviews on our
                <a
                  href="https://www.facebook.com/audiovultures/reviews"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer">
                  {' '}
                  Facebook page.
                </a>
              </p>
              <hr className="major" />

              <h2>Follow Our Instagram</h2>
              <p>
                <span className="icon alt fa-instagram" />{' '}
                <a
                  href="https://www.instagram.com/audiovultures/"
                  className="link"
                  target="_blank"
                  rel="noopener noreferrer">
                  Follow us on Instagram
                </a>{' '}
                for more photos and videos!
              </p>
            </div>
            <div className="6u 12u$(small)">
              <h2>Audio</h2>
              <iframe
                width="100%"
                height="390"
                scrolling="no"
                frameBorder="no"
                title="Audio Vultures Party Band"
                src="https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/playlists/983807023&color=%23ff1919&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true"
              />
            </div>
          </div>
        </div>
      </section>
      <hr className="major" />

      <DJService />
      <ClientsBanner />
    </div>
  </Layout>
);

export default ReviewsTemplate;
